import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { PriceFilter, SelectMultipleFilter } from '../../components';
import classNames from 'classnames';
import config from '../../config';
import DefaultFilterComponent from './DefaultFilterComponent';
import SizeFilter from './SizeFilter';
import TypeFilter from './TypeFilter';

import css from './SearchPage.module.css';

const GARMENTS = 'garments';
const ACCESSORIES = 'accessories';
const FOOTWEAR = 'footwear';
const LEATHER_GOODS = 'leatherGoods';

function FilterBar(props) {
  const { topbarMenuConfig, onSubmit: submit, rest } = props;
  const [showTab, setShowTab] = useState('');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const mainCategory = urlParams.get('pub_mainCategory');
  const newIn = urlParams.get('pub_condition');

  // Init
  let initGarmentsLength = urlParams.get('pub_garmentLength') || undefined;
  let initCategory = props.initialValues(['pub_category']);
  let initColour = urlParams.get('pub_colour');

  if (initColour) {
    initColour = {
      pub_colour: initColour.replace('has_any:', ''),
    };
  } else {
    initColour = {
      pub_colour: undefined,
    };
  }

  if (initGarmentsLength) {
    initGarmentsLength = {
      pub_garmentLength: initGarmentsLength?.replace('has_any:', ''),
    };
  } else {
    initGarmentsLength = {
      pub_garmentLength: undefined,
    };
  }

  const initShirtsPatterns = urlParams.get('pub_shirtsPatterns')
    ? { pub_shirtsPatterns: urlParams.get('pub_shirtsPatterns').replace('has_any:', '') }
    : { pub_shirtsPatterns: undefined };

  const initCondition = urlParams.get('pub_condition')
    ? {
        pub_condition: urlParams.get('pub_condition').replace('has_any:', ''),
      }
    : { pub_condition: undefined };
  const initGarmentsPatterns = urlParams.get('pub_garmentsPatterns')
    ? {
        pub_garmentsPatterns: urlParams.get('pub_garmentsPatterns').replace('has_any:', ''),
      }
    : { pub_garmentsPatterns: undefined };
  const initAccessoriesPatterns = urlParams.get('pub_accessoriesPatterns')
    ? {
        pub_accessoriesPatterns: urlParams.get('pub_accessoriesPatterns').replace('has_any:', ''),
      }
    : { pub_accessoriesPatterns: undefined };

  const selectedCategory = urlParams.get('pub_category')
    ? urlParams
        .get('pub_category')
        ?.replace('has_any:', '')
        ?.split(',')
    : [];

  // Accessories specific checks

  const accessoriesPatternVisibleCategories = [
    'ties',
    'pocket-squares',
    'bow-ties',
    'scarves',
    'watches',
    'miscellaneous',
  ];

  const showAccessoriesPatterns = !!selectedCategory.find(item =>
    accessoriesPatternVisibleCategories.includes(item)
  );

  const accessoriesAppearanceTabVisibleCategories = [
    'ties',
    'pocket-squares',
    'bow-ties',
    'scarves',
    'hats',
    'belts',
    'suspenders',
    'cufflinks',
  ];

  const showAppearanceTab = !!selectedCategory.find(item =>
    accessoriesAppearanceTabVisibleCategories.includes(item)
  );

  const accessoriesColourFilterVisibleCategories = [
    'ties',
    'pocket-squares',
    'bow-ties',
    'scarves',
    'hats',
    'belts',
    'suspenders',
    'watches',
    'miscellaneous',
  ];

  const showAccessoriesColourFilter = !!selectedCategory.find(item =>
    accessoriesColourFilterVisibleCategories.includes(item)
  );

  //Footwear Specific
  const footwearColourVisibleFilters = ['boots', 'oxfords', 'buckles', 'derbies', 'loafers'];
  const showFootwearColourFilter = !!selectedCategory.find(item =>
    footwearColourVisibleFilters.includes(item)
  );

  const onSubmit = rest => {
    setShowTab(false);
    submit(rest);
  };

  const handleSizeFilter = values => {
    const priceFilter = Object.keys(values)
      .map(key => {
        const arr = values[key].split(',');
        if (Number(arr[0]) === Number(arr[1])) return { [key]: Number(arr[0]) * 10 };
        else return { [key]: `${Number(arr[0]) * 10 - 1},${Number(arr[1]) * 10 + 1}` };
      })
      .reduce((acc, item) => ({ ...acc, ...item }), {});
    onSubmit(priceFilter);
  };

  const getInitials = key => {
    const initials = urlParams.get(key);

    if (!initials) return { [key]: undefined };

    if (initials?.indexOf(',') !== -1) {
      const k = initials.split(',');
      //prettier-ignore
      return { [key]: `${(Number(k[0]) + 1) / 10}, ${(Number(k[1]) - 1) / 10}` };
    }
    return { [key]: `${Number(initials) / 10},${Number(initials) / 10}` };
  };

  if (mainCategory) {
    switch (mainCategory) {
      case GARMENTS:
        return (
          <>
            <div className={css.mainFilters}>
              <ul className={css.filterList}>
                {selectedCategory.includes('official-vulpilist-attire') &&
                selectedCategory.length === 1 ? null : (
                  <>
                    <li className={css.filterItem}>
                      <SizeFilter
                        setShowTab={setShowTab}
                        showTab={showTab}
                        initGarmentsLength={initGarmentsLength}
                        config={config}
                        onSizeFilterSubmit={handleSizeFilter}
                        onSubmit={onSubmit}
                        rest={rest}
                        topbarMenuConfig={topbarMenuConfig}
                        initCategory={initCategory}
                      />
                    </li>
                    <li className={css.filterItem}>
                      <TypeFilter
                        setShowTab={setShowTab}
                        showTab={showTab}
                        initCategory={initCategory}
                        onSubmit={onSubmit}
                        config={config}
                        rest={rest}
                      />
                    </li>
                  </>
                )}
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'gar-appearance',
                    })}
                    onClick={() =>
                      showTab === 'gar-appearance'
                        ? setShowTab(false)
                        : setShowTab('gar-appearance')
                    }
                  >
                    <FormattedMessage id="Filters.appearance" />
                  </div>
                  <div
                    className={css.dropdown}
                    style={{
                      display: showTab === 'gar-appearance' ? 'grid' : 'none',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <SelectMultipleFilter
                        id="Colour"
                        label="Colour"
                        name="Colour"
                        queryParamNames="pub_colour"
                        initialValues={initColour}
                        showAsPopup={false}
                        liveEdit={false}
                        onSubmit={onSubmit}
                        options={config.custom.colour}
                        {...rest}
                      />
                    </div>
                    {selectedCategory.length == 1 ? (
                      selectedCategory.includes('knitwear') ||
                      selectedCategory.includes(
                        'official-vulpilist-attire'
                      ) ? null : selectedCategory.includes('shirts') ? (
                        <div className={css.dropdownItem}>
                          <SelectMultipleFilter
                            id={'shirtPatterns'}
                            label="Shirt patterns"
                            name="shirtPatterns"
                            queryParamNames="pub_shirtsPatterns"
                            initialValues={initShirtsPatterns}
                            showAsPopup={false}
                            liveEdit={false}
                            onSubmit={onSubmit}
                            options={config.custom.shirtPatterns}
                            {...rest}
                          />
                        </div>
                      ) : (
                        <div className={css.dropdownItem}>
                          <SelectMultipleFilter
                            id={'garmentsPatterns'}
                            label="Garments patterns"
                            name="garmentsPatterns"
                            queryParamNames="pub_garmentsPatterns"
                            initialValues={initGarmentsPatterns}
                            showAsPopup={false}
                            liveEdit={false}
                            onSubmit={onSubmit}
                            options={config.custom.garmentsPatterns}
                            {...rest}
                          />
                        </div>
                      )
                    ) : (
                      <>
                        {selectedCategory.includes('shirts') && (
                          <div className={css.dropdownItem}>
                            <SelectMultipleFilter
                              id={'shirtPatterns'}
                              label="Shirt patterns"
                              name="shirtPatterns"
                              queryParamNames="pub_shirtsPatterns"
                              initialValues={initShirtsPatterns}
                              showAsPopup={false}
                              liveEdit={false}
                              onSubmit={onSubmit}
                              options={config.custom.shirtPatterns}
                              {...rest}
                            />
                          </div>
                        )}
                        {selectedCategory.includes('shirts') &&
                        selectedCategory.includes('knitwear') &&
                        selectedCategory.length == 2 ? null : (
                          <div className={css.dropdownItem}>
                            <SelectMultipleFilter
                              id={'garmentsPatterns'}
                              label="Garments patterns"
                              name="garmentsPatterns"
                              queryParamNames="pub_garmentsPatterns"
                              initialValues={initGarmentsPatterns}
                              showAsPopup={false}
                              liveEdit={false}
                              onSubmit={onSubmit}
                              options={config.custom.garmentsPatterns}
                              {...rest}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </li>
                {selectedCategory.includes('official-vulpilist-attire') &&
                selectedCategory.length == 1 ? null : (
                  <li className={css.filterItem}>
                    <div
                      className={classNames(css.filterHeading, {
                        [css.activeFilterHeading]: showTab === 'gar-condition',
                        gridTemplateColumns: '1fr',
                      })}
                      onClick={() =>
                        showTab === 'gar-condition'
                          ? setShowTab(false)
                          : setShowTab('gar-condition')
                      }
                    >
                      <FormattedMessage id="Filters.condition" />
                    </div>
                    <div
                      className={css.dropdown}
                      style={{
                        display: showTab === 'gar-condition' ? 'grid' : 'none',
                        gridTemplateColumns: '1fr',
                      }}
                    >
                      <div className={css.dropdownItem}>
                        <SelectMultipleFilter
                          id="condition"
                          label="Condition"
                          name="condition"
                          queryParamNames="pub_condition"
                          initialValues={initCondition}
                          showAsPopup={false}
                          liveEdit={false}
                          onSubmit={onSubmit}
                          options={config.custom.condition}
                          {...rest}
                        />
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        );
      case ACCESSORIES:
        return (
          <>
            <div className={css.mainFilters}>
              <ul className={css.filterList}>
                {showAppearanceTab && (showAccessoriesColourFilter || showAccessoriesPatterns) && (
                  <li className={css.filterItem}>
                    <div
                      className={classNames(css.filterHeading, {
                        [css.activeFilterHeading]: showTab === 'access-appearance',
                      })}
                      onClick={() =>
                        showTab === 'access-appearance'
                          ? setShowTab(false)
                          : setShowTab('access-appearance')
                      }
                    >
                      <FormattedMessage id="Filters.appearance" />
                    </div>
                    <div
                      className={css.dropdown}
                      style={{
                        display: showTab === 'access-appearance' ? 'grid' : 'none',
                      }}
                    >
                      {showAccessoriesColourFilter && (
                        <div className={css.dropdownItem}>
                          <SelectMultipleFilter
                            id="access-colour"
                            label="Colour"
                            name="access-Colour"
                            queryParamNames="pub_colour"
                            initialValues={initColour}
                            showAsPopup={false}
                            liveEdit={false}
                            onSubmit={onSubmit}
                            options={config.custom.colour}
                            {...rest}
                          />
                        </div>
                      )}
                      {showAccessoriesPatterns && (
                        <div className={css.dropdownItem}>
                          <SelectMultipleFilter
                            id="access-garmentPatterns"
                            label="Accessories patterns"
                            name="access-garmentPatterns"
                            queryParamNames="pub_accessoriesPatterns"
                            initialValues={initAccessoriesPatterns}
                            showAsPopup={false}
                            liveEdit={false}
                            onSubmit={onSubmit}
                            options={config.custom.accessoriesPatterns}
                            {...rest}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                )}
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'access-condition',
                      gridTemplateColumns: '1fr',
                    })}
                    onClick={() =>
                      showTab === 'access-condition'
                        ? setShowTab(false)
                        : setShowTab('access-condition')
                    }
                  >
                    <FormattedMessage id="Filters.condition" />
                  </div>
                  <div
                    className={css.dropdown}
                    style={{
                      display: showTab === 'access-condition' ? 'grid' : 'none',
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <SelectMultipleFilter
                        id="access-condition"
                        label="Condition"
                        name="access-condition"
                        queryParamNames="pub_condition"
                        initialValues={initCondition}
                        showAsPopup={false}
                        liveEdit={false}
                        onSubmit={onSubmit}
                        options={config.custom.condition}
                        {...rest}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        );
      case FOOTWEAR:
        return (
          <>
            <div className={css.mainFilters}>
              <ul className={css.filterList}>
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'footwear-size',
                    })}
                    onClick={() =>
                      showTab === 'footwear-size' ? setShowTab(false) : setShowTab('footwear-size')
                    }
                  >
                    <FormattedMessage id="Filters.size" />
                  </div>
                  <div
                    className={classNames(css.footwearSizeDropdown, css.dropdown)}
                    style={{
                      display: showTab === 'footwear-size' ? 'grid' : 'none',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <PriceFilter
                        id="Footwear (UK size)"
                        label="Footwear (UK size)"
                        modLabel="Footwear (UK size)"
                        queryParamNames={['pub_footwearSizeUk']}
                        initialValues={getInitials('pub_footwearSizeUk')}
                        {...{ min: 5, max: 14, step: 0.5 }}
                        onSubmit={handleSizeFilter}
                        {...rest}
                        showAsPopup
                      />
                    </div>
                  </div>
                </li>
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'footwear-condition',
                    })}
                    onClick={() =>
                      showTab === 'footwear-condition'
                        ? setShowTab(false)
                        : setShowTab('footwear-condition')
                    }
                  >
                    <FormattedMessage id="Filters.condition" />
                  </div>
                  <div
                    className={css.dropdown}
                    style={{
                      display: showTab === 'footwear-condition' ? 'grid' : 'none',
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <SelectMultipleFilter
                        id="footwear-condition"
                        label="Condition"
                        name="footwear-condition"
                        queryParamNames="pub_condition"
                        initialValues={initCondition}
                        showAsPopup={false}
                        liveEdit={false}
                        onSubmit={onSubmit}
                        options={config.custom.condition}
                        {...rest}
                      />
                    </div>
                  </div>
                </li>
                {showFootwearColourFilter && (
                  <li className={css.filterItem}>
                    <div
                      className={classNames(css.filterHeading, {
                        [css.activeFilterHeading]: showTab === 'footwear-appearance',
                      })}
                      onClick={() =>
                        showTab === 'footwear-appearance'
                          ? setShowTab(false)
                          : setShowTab('footwear-appearance')
                      }
                    >
                      <FormattedMessage id="Filters.appearance" />
                    </div>
                    <div
                      className={css.dropdown}
                      style={{
                        display: showTab === 'footwear-appearance' ? 'grid' : 'none',
                        gridTemplateColumns: '1fr',
                      }}
                    >
                      <div className={css.dropdownItem}>
                        <SelectMultipleFilter
                          id="footwear-colour"
                          label="Colour"
                          name="footwear-colour"
                          queryParamNames="pub_colour"
                          initialValues={initColour}
                          showAsPopup={false}
                          liveEdit={false}
                          onSubmit={onSubmit}
                          options={config.custom.colour}
                          {...rest}
                        />
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        );
      case LEATHER_GOODS:
        return (
          <>
            <div className={css.mainFilters}>
              <ul className={css.filterList}>
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'leather-condition',
                      gridTemplateColumns: '1fr',
                    })}
                    onClick={() =>
                      showTab === 'leather-condition'
                        ? setShowTab(false)
                        : setShowTab('leather-condition')
                    }
                  >
                    <FormattedMessage id="Filters.condition" />
                  </div>
                  <div
                    className={css.dropdown}
                    style={{
                      display: showTab === 'leather-condition' ? 'grid' : 'none',
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <SelectMultipleFilter
                        id="leather-condition"
                        label="Condition"
                        name="leather-condition"
                        queryParamNames="pub_condition"
                        initialValues={initCondition}
                        showAsPopup={false}
                        liveEdit={false}
                        onSubmit={onSubmit}
                        options={config.custom.condition}
                        {...rest}
                      />
                    </div>
                  </div>
                </li>
                <li className={css.filterItem}>
                  <div
                    className={classNames(css.filterHeading, {
                      [css.activeFilterHeading]: showTab === 'leather-appearance',
                    })}
                    onClick={() =>
                      showTab === 'leather-appearance'
                        ? setShowTab(false)
                        : setShowTab('leather-appearance')
                    }
                  >
                    <FormattedMessage id="Filters.appearance" />
                  </div>
                  <div
                    className={css.dropdown}
                    style={{
                      display: showTab === 'leather-appearance' ? 'grid' : 'none',
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <div className={css.dropdownItem}>
                      <SelectMultipleFilter
                        id="leather-appearance"
                        label="Colour"
                        name="leather-appearance"
                        queryParamNames="pub_colour"
                        initialValues={initColour}
                        showAsPopup={false}
                        liveEdit={false}
                        onSubmit={onSubmit}
                        options={config.custom.colour}
                        {...rest}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        );
      default:
        null;
    }
  } else if (newIn && mainCategory != null) {
    return null;
  } else
    return (
      <DefaultFilterComponent
        onSubmit={submit}
        showTab={showTab}
        getInitials={getInitials}
        setShowTab={setShowTab}
        onSizeFilterSubmit={handleSizeFilter}
        initCategory={initCategory}
        custom={config.custom}
        initialValues={props.initialValues}
        urlParams={urlParams}
        initGarmentsLength={initGarmentsLength}
      />
    );

  return null;
}

FilterBar.defaultProps = {
  topbarMenuConfig: config.custom.topbarMenuConfig,
};

export default FilterBar;
