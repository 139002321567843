import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { SelectMultipleFilter } from '../../components';

import css from './SearchPage.module.css';

function TypeFilter(props) {
  const { initCategory, onSubmit, config, rest, style, showTab, setShowTab } = props;
  return (
    <>
      <div
        onClick={() => (showTab == 'gar-type' ? setShowTab(false) : setShowTab('gar-type'))}
        className={css.filterHeading}
      >
        <FormattedMessage id="Filters.type" />
      </div>
      <div
        className={css.dropdown}
        style={{
          display: showTab == 'gar-type' ? 'grid' : 'none',
          gridTemplateColumns: '1fr',
        }}
      >
        <div className={css.dropdownItem}>
          <SelectMultipleFilter
            className={css.buttonFilter}
            id="Categories"
            label="Categories"
            name="categories"
            queryParamNames="pub_category"
            initialValues={initCategory}
            showAsPopup={false}
            liveEdit={false}
            onSubmit={onSubmit}
            options={config.custom.garments}
            {...rest}
          />
        </div>
      </div>
    </>
  );
}

export default TypeFilter;
