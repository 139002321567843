import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PriceFilter, SelectMultipleFilter } from '../../components';
import classNames from 'classnames';
import config from '../../config';

import css from './SearchPage.module.css';

function DefaultFilterComponent({
  onSubmit,
  custom,
  rest,
  initCategory,
  showTab,
  setShowTab,
  onSizeFilterSubmit,
  urlParams,
  initGarmentsLength,
  getInitials,
}) {
  const jacketsOptions = { min: 32, max: 60, step: 1 };
  const trousersOptions = { min: 27, max: 50, step: 1 };
  const shirtsOptions = { min: 14, max: 19, step: 1 };
  const footwearOptions = { min: 5, max: 14, step: 1 };

  let initGarmentsCategories = initCategory?.pub_category
    ?.split(',')
    ?.filter(i => custom.garments.map(i => i.key).includes(i));

  if (initGarmentsCategories) {
    initGarmentsCategories = { pub_category: initGarmentsCategories?.join(',') };
  }
  let initFootwearCategories = initCategory?.pub_category
    ?.split(',')
    ?.filter(i => custom.footwear.map(i => i.key).includes(i));

  if (initFootwearCategories) {
    initFootwearCategories = { pub_category: initFootwearCategories?.join(',') };
  }
  let initAccessoriesCategories = initCategory?.pub_category
    ?.split(',')
    ?.filter(i => custom.accessories.map(i => i.key).includes(i));

  if (initAccessoriesCategories) {
    initAccessoriesCategories = { pub_category: initAccessoriesCategories?.join(',') };
  }
  let initLeatherGoodsCategories = initCategory?.pub_category
    ?.split(',')
    ?.filter(i => custom.leatherGoods.map(i => i.key).includes(i));

  if (initLeatherGoodsCategories) {
    initLeatherGoodsCategories = { pub_category: initLeatherGoodsCategories?.join(',') };
  }

  const initColour = urlParams.get('pub_colour')
    ? {
        pub_colour: urlParams.get('pub_colour').replace('has_any:', ''),
      }
    : { pub_colour: undefined };
  const initCondition = urlParams.get('pub_condition')
    ? {
        pub_condition: urlParams.get('pub_condition').replace('has_any:', ''),
      }
    : { pub_condition: undefined };
  const initGarmentsPatterns = urlParams.get('pub_garmentsPatterns')
    ? {
        pub_garmentsPatterns: urlParams.get('pub_garmentsPatterns').replace('has_any:', ''),
      }
    : { pub_garmentsPatterns: undefined };
  const initShirtsPatterns = urlParams.get('pub_shirtsPatterns')
    ? {
        pub_shirtsPatterns: urlParams.get('pub_shirtsPatterns').replace('has_any:', ''),
      }
    : { pub_shirtsPatterns: undefined };
  const initAccessoriesPatterns = urlParams.get('pub_accessoriesPatterns')
    ? {
        pub_accessoriesPatterns: urlParams.get('pub_accessoriesPatterns').replace('has_any:', ''),
      }
    : { pub_accessoriesPatterns: undefined };

  const onSubmitCategory = (category, categoryName) => {
    if (category.pub_category == null) {
      return onSubmit(category);
    }

    if (initCategory.pub_category) {
      let newCategory = [];
      const prevLeatherGoods = initLeatherGoodsCategories.pub_category
        ? initLeatherGoodsCategories.pub_category.split(',')
        : [];
      const prevGarments = initGarmentsCategories.pub_category
        ? initGarmentsCategories.pub_category.split(',')
        : [];
      const prevAccessories = initAccessoriesCategories.pub_category
        ? initAccessoriesCategories.pub_category.split(',')
        : [];
      const prevFootwear = initFootwearCategories.pub_category
        ? initFootwearCategories.pub_category.split(',')
        : [];
      switch (categoryName) {
        case 'garments':
          newCategory = [...prevAccessories, ...prevFootwear, ...prevLeatherGoods];
          break;
        case 'footwear':
          newCategory = [...prevGarments, ...prevAccessories, ...prevLeatherGoods];
          break;
        case 'leatherGoods':
          newCategory = [...prevAccessories, ...prevFootwear, ...prevGarments];
          break;
        case 'accessories':
          newCategory = [...prevGarments, ...prevFootwear, ...prevLeatherGoods];
          break;
      }

      const temp2 = category.pub_category.split(',');
      newCategory = [...new Set([...newCategory, ...temp2])].join(',');
      return onSubmit({ pub_category: newCategory });
    }
    onSubmit(category);
  };
  return (
    <div className={css.mainFilters}>
      <ul className={css.filterList}>
        <li className={css.filterItem}>
          <div
            className={classNames(css.filterHeading, {
              [css.activeFilterHeading]: showTab === 'size-container',
            })}
            onClick={() =>
              showTab === 'size-container' ? setShowTab(false) : setShowTab('size-container')
            }
          >
            <FormattedMessage id="Filters.size" />
          </div>
          <div
            className={css.dropdown}
            style={{
              display: showTab === 'size-container' ? 'grid' : 'none',
            }}
          >
            <div className={classNames(css.dropdownButtonsItem, css.dropdownItem)}>
              <PriceFilter
                className={classNames(css.noWrap, css.buttonFilter)}
                id="jacketsandCoats"
                label={`Jackets, coats and knitwear (UK size - chest)`}
                modLabel="Jackets, coats and knitwear (UK size - chest)"
                queryParamNames={['pub_jacketsSuitsCoatsAndKnitwearUkSizeChest']}
                initialValues={getInitials('pub_jacketsSuitsCoatsAndKnitwearUkSizeChest')}
                {...jacketsOptions}
                onSubmit={onSizeFilterSubmit}
                {...rest}
                showAsPopup
              />

              <PriceFilter
                className={css.buttonFilter}
                id="Trousers (UK size - waist)"
                label={`Trousers (UK size - waist)`}
                modLabel="Trousers (UK size - waist)"
                queryParamNames={['pub_trousersSizeWaist']}
                initialValues={getInitials('pub_trousersSizeWaist')}
                {...trousersOptions}
                onSubmit={onSizeFilterSubmit}
                {...rest}
                showAsPopup
              />
              <PriceFilter
                className={css.buttonFilter}
                id="Shirts (UK size - neck)"
                label={`Shirts (UK size - neck)`}
                modLabel="Shirts (UK size - neck)"
                queryParamNames={['pub_shirtsSizeCollar']}
                initialValues={getInitials('pub_shirtsSizeCollar')}
                {...shirtsOptions}
                onSubmit={onSizeFilterSubmit}
                {...rest}
                showAsPopup
              />
              <PriceFilter
                className={css.buttonFilter}
                id="Footwear (UK size)"
                label="Footwear (UK size)"
                modLabel="Footwear (UK size)"
                queryParamNames={['pub_footwearSizeUk']}
                initialValues={getInitials('pub_footwearSizeUk')}
                {...footwearOptions}
                onSubmit={onSizeFilterSubmit}
                {...rest}
                showAsPopup
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                className={css.filterSelection}
                id="GarmentsLength"
                label="Garments length"
                name="garmentLength"
                queryParamNames="pub_garmentLength"
                initialValues={initGarmentsLength}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={config.custom.garmentLength}
                {...rest}
              />
            </div>
          </div>
        </li>
        <li className={css.filterItem}>
          <div
            className={classNames(css.filterHeading, {
              [css.activeFilterHeading]: showTab === 'type-container',
            })}
            onClick={() =>
              showTab === 'type-container' ? setShowTab(false) : setShowTab('type-container')
            }
          >
            <FormattedMessage id="Filters.type" />
          </div>
          <div
            className={css.dropdown}
            style={{
              display: showTab === 'type-container' ? 'grid' : 'none',
            }}
          >
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="Garments"
                label="Garments"
                name="Garments"
                queryParamNames="pub_category"
                initialValues={initGarmentsCategories}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={values => onSubmitCategory(values, 'garments')}
                options={custom.garments}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="Footwear"
                label="Footwear"
                name="footwear"
                queryParamNames="pub_category"
                initialValues={initFootwearCategories}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={values => onSubmitCategory(values, 'footwear')}
                options={custom.footwear}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="leatherGoods"
                label="Leather Goods"
                name="leatherGoods"
                queryParamNames="pub_category"
                initialValues={initLeatherGoodsCategories}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={values => onSubmitCategory(values, 'leatherGoods')}
                options={custom.leatherGoods}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="accessories"
                label="Accessories"
                name="accessories"
                queryParamNames="pub_category"
                initialValues={initAccessoriesCategories}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={values => onSubmitCategory(values, 'accessories')}
                options={custom.accessories}
                {...rest}
              />
            </div>
          </div>
        </li>
        <li className={css.filterItem}>
          <div
            className={classNames(css.filterHeading, {
              [css.activeFilterHeading]: showTab === 'appearance-container',
            })}
            onClick={() =>
              showTab === 'appearance-container'
                ? setShowTab(false)
                : setShowTab('appearance-container')
            }
          >
            <FormattedMessage id="Filters.appearance" />
          </div>
          <div
            className={css.dropdown}
            style={{
              display: showTab === 'appearance-container' ? 'grid' : 'none',
            }}
          >
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="Colour"
                label="Colour"
                name="Colour"
                queryParamNames="pub_colour"
                initialValues={initColour}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={custom.colour}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="garmentsPatterns"
                label="Garment patterns"
                name="garmentsPatterns"
                queryParamNames="pub_garmentsPatterns"
                initialValues={initGarmentsPatterns}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={custom.garmentsPatterns}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="shirtsPatterns"
                label="Shirt patterns"
                name="shirtsPatterns"
                queryParamNames="pub_shirtsPatterns"
                initialValues={initShirtsPatterns}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={custom.shirtPatterns}
                {...rest}
              />
            </div>
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="accessoriesPatterns"
                label="Accesories patterns"
                name="accessoriesPatterns"
                queryParamNames="pub_accessoriesPatterns"
                initialValues={initAccessoriesPatterns}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={custom.accessoriesPatterns}
                {...rest}
              />
            </div>
          </div>
        </li>
        <li className={css.filterItem}>
          <div
            className={classNames(css.filterHeading, {
              [css.activeFilterHeading]: showTab === 'condition-container',
            })}
            onClick={() =>
              showTab === 'condition-container'
                ? setShowTab(false)
                : setShowTab('condition-container')
            }
          >
            <FormattedMessage id="Filters.condition" />
          </div>
          <div
            className={css.dropdown}
            style={{
              display: showTab === 'condition-container' ? 'grid' : 'none',
              gridTemplateColumns: '1fr',
            }}
          >
            <div className={css.dropdownItem}>
              <SelectMultipleFilter
                id="leather-condition"
                label="Condition"
                name="leather-condition"
                queryParamNames="pub_condition"
                initialValues={initCondition}
                showAsPopup={false}
                liveEdit={false}
                onSubmit={onSubmit}
                options={custom.condition}
                {...rest}
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default DefaultFilterComponent;
