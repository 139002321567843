import React from 'react';
// import {
//   BookingDateRangeFilter,
//   PriceFilter,
//   KeywordFilter,
//   SelectSingleFilter,
//   SelectMultipleFilter,
// } from '../../components';
import config from '../../config';
import FilterBar from './FilterBar';

import css from './SearchPage.module.css';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    customConfig,
    ...rest
  } = props;
  const { id, type, queryParamNames, label, config } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (type) {
    case 'SelectSingleFilter': {
      // <SelectSingleFilter
      //   id={componentId}
      //   label={label}
      //   queryParamNames={queryParamNames}
      //   initialValues={initialValues(queryParamNames)}
      //   onSelect={getHandleChangedValueFn(useHistoryPush)}
      //   {...config}
      //   {...rest}
      // />
      return null;
    }
    case 'SelectMultipleFilter': {
      // <SelectMultipleFilter
      //   id={componentId}
      //   label={label}
      //   name={name}
      //   queryParamNames={queryParamNames}
      //   initialValues={initialValues(queryParamNames)}
      //   onSubmit={getHandleChangedValueFn(useHistoryPush)}
      //   {...config}
      //   {...rest}
      // />
      return null;
    }
    case 'BookingDateRangeFilter': {
      // <BookingDateRangeFilter
      //   id={componentId}
      //   label={label}
      //   queryParamNames={queryParamNames}
      //   initialValues={initialValues(queryParamNames)}
      //   onSubmit={getHandleChangedValueFn(useHistoryPush)}
      //   {...config}
      //   {...rest}
      // />
      return null;
    }
    case 'PriceFilter': {
      // <PriceFilter
      //   id={componentId}
      //   label={label}
      //   queryParamNames={queryParamNames}
      //   initialValues={initialValues(queryParamNames)}
      //   onSubmit={getHandleChangedValueFn(useHistoryPush)}
      //   {...config}
      //   {...rest}
      // />
      return null;
    }
    case 'KeywordFilter':
      // <KeywordFilter
      //   id={componentId}
      //   label={label}
      //   name={name}
      //   queryParamNames={queryParamNames}
      //   initialValues={initialValues(queryParamNames)}
      //   onSubmit={getHandleChangedValueFn(useHistoryPush)}
      //   {...config}
      //   {...rest}
      // />
      return null;
    default:
      // return null;

      return (
        <div className={css.topFilters}>
          <FilterBar
            id={componentId}
            label={label}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        </div>
      );
  }
};

FilterComponent.defaultProps = {
  customConfig: config.custom,
};

export default FilterComponent;
