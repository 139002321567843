import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PriceFilter, SelectMultipleFilter } from '../../components';
import classNames from 'classnames';

import css from './SearchPage.module.css';
import { useLocation } from 'react-router-dom';

const SHOP_BY_SIZE = 'shop-by-size';

function SizeFilter(props) {
  const {
    initGarmentsLength,
    config,
    onSubmit,
    rest,
    topbarMenuConfig,
    showTab,
    setShowTab,
    onSizeFilterSubmit,
  } = props;

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  let sizeOptions = topbarMenuConfig?.find(i => i.key === SHOP_BY_SIZE)?.items;

  const garmentSizes = sizeOptions?.filter(item => {
    const itemKeys = item.key.split('-and-');
    return !!config.custom.garments.find(i => i.key == itemKeys[0]) ? true : false;
  });

  const selectedCategories = urlParams.get('pub_category')
    ? urlParams
        .get('pub_category')
        ?.replace('has_any:', '')
        ?.split(',')
    : [];

  const jacketSizeCategories = [
    'jackets',
    'suits',
    'waistcoat',
    'dressingGowns',
    'knitwear',
    'coats',
  ];
  const shirtSizeCategories = ['shirts'];
  const trouserSizeCategories = ['trousers'];
  const allCategories = ['official-vulpilist-attire'];

  const displayJacketsSizesOnly = jacketSizeCategories.some(item =>
    selectedCategories.includes(item)
  );
  const displayShirtSizesOnly = shirtSizeCategories.some(item => selectedCategories.includes(item));
  const displayTrouserSizesOnly = trouserSizeCategories.some(item =>
    selectedCategories.includes(item)
  );
  const displayAllCategories = allCategories.some(item => selectedCategories.includes(item));

  const showCategories =
    displayAllCategories ||
    displayJacketsSizesOnly ||
    displayShirtSizesOnly ||
    displayTrouserSizesOnly;

  const getInitials = key => {
    const initials = urlParams.get(key);

    if (!initials) return { [key]: undefined };

    if (initials?.indexOf(',') !== -1) {
      const k = initials.split(',');

      //prettier-ignore
      return { [key]: `${((Number(k[0]) + 1) / 10) }, ${(Number(k[1] - 1) / 10)}` };
    }
    return { [key]: `${Number(initials) / 10},${Number(initials) / 10}` };
  };

  return (
    <>
      <div
        className={css.filterHeading}
        onClick={() => (showTab == 'gar-size' ? setShowTab(false) : setShowTab('gar-size'))}
      >
        <FormattedMessage id="Filters.size" />
      </div>
      <div
        className={css.dropdown}
        style={{
          display: showTab == 'gar-size' ? 'grid' : 'none',
        }}
      >
        {showCategories ? (
          <div className={classNames(css.dropdownButtonsItem, css.dropdownItem)}>
            <label className={css.sizesLabel}>Sizes</label>
            {garmentSizes?.map(garmentSize => {
              const options =
                garmentSize.key === 'jackets-and-knitwear-and-coats-and-suits'
                  ? { min: 32, max: 60, step: 1 }
                  : garmentSize.key === 'shirts'
                  ? { min: 14, max: 19, step: 1 }
                  : { min: 27, max: 50, step: 1 };
              const isJacketKey =
                garmentSize.key === 'jackets-and-knitwear-and-coats-and-suits'
                  ? garmentSize.key.split('-and-')
                  : garmentSize.key.split(',');
              console.log(garmentSize);
              return displayAllCategories ? (
                <PriceFilter
                  key={garmentSize.key}
                  className={css.buttonFilter}
                  id={garmentSize.label}
                  label={`${garmentSize.label} Size`}
                  modLabel={garmentSize.label}
                  queryParamNames={
                    garmentSize.key === 'trousers'
                      ? ['pub_trousersSizeWaist']
                      : garmentSize.key === 'shirts'
                      ? ['pub_shirtsSizeCollar']
                      : ['pub_jacketsSuitsCoatsAndKnitwearUkSizeChest']
                  }
                  initialValues={getInitials(
                    garmentSize.key === 'trousers'
                      ? 'pub_trousersSizeWaist'
                      : garmentSize.key === 'shirts'
                      ? 'pub_shirtsSizeCollar'
                      : 'pub_jacketsSuitsCoatsAndKnitwearUkSizeChest'
                  )}
                  {...options}
                  onSubmit={onSizeFilterSubmit}
                  {...rest}
                  showAsPopup
                />
              ) : jacketSizeCategories.includes(isJacketKey[0]) ? (
                displayJacketsSizesOnly && (
                  <PriceFilter
                    className={css.buttonFilter}
                    id={garmentSize.label}
                    label={`${garmentSize.label} Size`}
                    modLabel={garmentSize.label}
                    queryParamNames={['pub_jacketsSuitsCoatsAndKnitwearUkSizeChest']}
                    initialValues={getInitials('pub_jacketsSuitsCoatsAndKnitwearUkSizeChest')}
                    {...options}
                    onSubmit={onSizeFilterSubmit}
                    {...rest}
                    showAsPopup
                  />
                )
              ) : shirtSizeCategories.includes(garmentSize.key.split(',')[0]) ? (
                displayShirtSizesOnly && (
                  <PriceFilter
                    className={css.buttonFilter}
                    id={garmentSize.label}
                    label={`${garmentSize.label} Size`}
                    modLabel={garmentSize.label}
                    queryParamNames={['pub_shirtsSizeCollar']}
                    initialValues={getInitials('pub_shirtsSizeCollar')}
                    {...options}
                    onSubmit={onSizeFilterSubmit}
                    {...rest}
                    showAsPopup
                  />
                )
              ) : trouserSizeCategories.includes(garmentSize.key.split(',')[0]) ? (
                displayTrouserSizesOnly && (
                  <PriceFilter
                    className={css.buttonFilter}
                    id={garmentSize.label}
                    label={`${garmentSize.label} Size`}
                    modLabel={garmentSize.label}
                    queryParamNames={['pub_trousersSizeWaist']}
                    initialValues={getInitials('pub_trousersSizeWaist')}
                    {...options}
                    onSubmit={onSizeFilterSubmit}
                    {...rest}
                    showAsPopup
                  />
                )
              ) : null;
            })}
          </div>
        ) : null}
        <div className={css.dropdownItem}>
          <SelectMultipleFilter
            className={css.filterSelection}
            id="GarmentsLength"
            label="Garments length"
            name="garmentLength"
            queryParamNames="pub_garmentLength"
            initialValues={initGarmentsLength}
            showAsPopup={false}
            liveEdit={false}
            onSubmit={onSubmit}
            options={config.custom.garmentLength}
            {...rest}
          />
        </div>
      </div>
    </>
  );
}

export default SizeFilter;
